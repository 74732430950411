.Services {
  &__header {
    width: 100%;
    padding-left: 50px;
  }

  &__title {
    background-image: url(../../assets/images/home/text-dot-circles.png);
    background-repeat: no-repeat;
    background-position: left;
    padding: 50px;
    margin-left: 100px;
    margin-top: 100px;
    max-width: 550px;
    font-size: 30px;
    font-weight: 700;
    color: #063768;
  }

  &__item {
    padding: 100px;
    margin: auto;
    max-width: 1800px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #063768;

    & img {
      max-width: 100%;
      width: 429px;
    }

    & h4 {
      line-height: 2.8rem;
      font-weight: 700;
    }

    &__content {
      padding: 100px;
      line-height: 2.5rem;

      & h4 {
        max-width: 400px;
      }

      & p {
        max-width: 400px;
      }
    }

    &__consultancy {
      background-image: url(../../assets/images/services/dotted-circles.png);
      background-repeat: no-repeat;
      background-position: top right;
    }

    &__procurement {
      background-image: url(../../assets/images/services/bg-blue-short.png);
      background-repeat: no-repeat;
      background-position: 104% 0%;
    }

    &__repair {
      background-image: url(../../assets/images/services/bg-blue.png);
      background-repeat: no-repeat;
      background-position: -4% 0%;
    }

    &__support {
      background-image: url(../../assets/images/services/bg-blue.png);
      background-repeat: no-repeat;
      background-position: 104% 0%;
    }
  }

  &__projects {
    background-image: url(../../assets/images/services/dotted-circles.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #f2f2f2;
    min-height: 500px;
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Services {
    &__item {
      padding: 50px !important;
      flex-wrap: wrap;

      &__content {
        padding: 50px !important;
      }

      &--reverse {
        & img {
          order: 1;
        }

        & .Services__item__content {
          order: 2;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Services {
    &__title {
      padding: 20px !important;
      margin-left: 20px !important;
    }
  }
}
