.ItLayout {
  &__header {
    min-height: 600px;
    background: linear-gradient(
      102.31deg,
      #833dcb 0%,
      #6d55ff 63.9%,
      #558cff 95.78%
    );
    display: flex;

    &__content {
      padding: 100px;
      padding-top: 200px;
      color: #fff;
      max-width: 490px;
      line-height: 2.3rem;

      & h1 {
        font-weight: 700;
      }
    }

    &__img {
      max-width: 100%;
      margin-left: auto;
      margin-top: -480px;
      text-align: right;
      overflow: hidden;

      & img {
        margin-right: -50px;
      }
    }
  }

  &__headline {
    padding: 50px;
    margin: auto;
    margin-top: 100px;
    display: flex;
    max-width: 1800px;
    justify-content: space-around;
    background-image: url(../../assets/images/itLayout/light-blue-bg.png);
    background-repeat: no-repeat;
    background-position: -2% 56%;

    & > img {
      max-width: 100%;
      max-height: 500px;
    }

    &__content {
      color: $brand-color-darker;
      line-height: 2.3rem;
      padding: 50px;
      background-image: url(../../assets/images/home/text-dot-circles.png);
      background-repeat: no-repeat;
      background-position: top left;

      & h2 {
        font-weight: 700;
      }

      & p {
        max-width: 450px;
      }
    }
  }

  &__solutions {
    color: $brand-color-darker;
    padding: 100px;
    max-width: 1800px;
    margin: 100px auto;
    background-image: url(../../assets/images/itLayout/light-blue-bg-lg.png);
    background-repeat: no-repeat;
    background-position: left;

    & .col-md-4 {
      margin: 30px 0;
    }

    &__title {
      background-image: url(../../assets/images/home/text-dot-circles.png);
      background-repeat: no-repeat;
      background-position: top left;
      padding: 20px;
      padding-left: 30px;
      padding-top: 30px;

      & h2 {
        font-weight: 700;
      }
    }

    &__card {
      background-color: transparent;
      border: 1px solid #8080803a;
      border-radius: 20px;
      padding: 30px;
      line-height: 2.3rem;
      min-height: 320px;
      height: 100%;
      margin: 20px auto;
      margin-bottom: 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: linear-gradient(
          102.31deg,
          #833dcb 0%,
          #6d55ff 63.9%,
          #558cff 95.78%
        ) !important;
        color: #fff !important;
        border: 1px solid transparent !important;
        box-shadow: 0px 10px 30px #6c55ff8e;

        // & > .ItLayout__solutions__card__icon {
        //   background-color: #fff !important;
        // }
      }

      & h4 {
        font-weight: 600;
        margin-top: 50px;
      }

      &__icon {
        min-height: 60px;
        max-width: 60px;
        margin-bottom: 30px;
        background-color: transparent;
        border-radius: 15px;

        & svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__whyus {
    color: $brand-color-darker;
    padding: 100px;
    background-color: #f2f2f2;
    min-height: 500px;
    margin: 100px 0;
    background-image: url(../../assets/images/itLayout/whyus-divider.png);
    background-repeat: no-repeat;
    background-position: 3.5% 80%;

    & .col-md-4 {
      margin: 40px auto;
    }

    &__inner {
      max-width: 1800px;
      margin: auto;
    }

    &__title {
      background-image: url(../../assets/images/home/text-dot-circles.png);
      background-repeat: no-repeat;
      background-position: top right;
      padding: 20px;
      padding-top: 30px;
      text-align: center;
      max-width: 600px;
      margin: auto;

      & h2 {
        font-weight: 700;
      }
    }

    &__card {
      margin: auto;

      &__img {
        margin: auto;
        margin-bottom: 40px;

        & img {
          border-radius: 20px;
          width: 100%;
          height: 270px;
          object-fit: cover;
        }
      }

      &__content {
        & h5 {
          font-weight: 700;
        }

        & p {
          line-height: 2rem;
          max-width: 380px;
        }
      }
    }
  }

  &__info {
    background-image: url(../../assets/images/services/dotted-circles.png);
    background-repeat: no-repeat;
    background-position: -10%;
    color: $brand-color-darker;
    min-height: 700px;

    &__inner {
      max-width: 1800px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    &__img {
      max-width: 100%;
      text-align: right;
      overflow: hidden;

      & img {
        max-width: 100%;
        margin-top: 80px;
        margin-right: -40px;
      }
    }

    &__content {
      padding: 100px;
      line-height: 2.3rem;
      margin: auto;

      & h3 {
        font-weight: 700;
        line-height: 3rem;
        max-width: 400px;
      }

      & p {
        max-width: 500px;
      }
    }
  }

  &__clients {
    background-color: #f2f2f2;
    min-height: 400px;
    padding: 100px;
    background-image: url(../../assets/images/services/dotted-circles.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-bottom: 100px;

    &__inner {
      max-width: 1800px;
      margin: auto;
    }

    & h3 {
      color: $brand-color-darker;
      text-align: center;
    }

    &__items {
      padding: 80px;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      padding: 50px 25px;
      text-align: center;
      border-right: 0.5px solid #eeeeee;
      border-bottom: 0.5px solid #eeeeee;

      &:last-of-type {
        border-right: none;
      }

      & img {
        max-width: 150px;

        filter: grayscale(100%);
        transition: filter 0.8s ease-in-out;

        &:hover {
          filter: grayscale(0%) !important;
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .ItLayout {
    &__header {
      &__content {
        margin-left: 150px;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-xl) {
  .ItLayout {
    &__header {
      &__content {
        text-align: center;
        margin: auto;
      }

      & > img {
        margin: auto;
      }

      &__img {
        display: none;
      }
    }

    &__info {
      &__content {
        text-align: center;

        & h3 {
          margin: auto;
        }
      }

      &__img {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .ItLayout {
    &__headline {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .ItLayout {
    &__header {
      &__content {
        padding: 20px !important;
        padding-top: 200px;
      }
    }

    &__solutions {
      padding: 20px !important;
    }

    &__whyus {
      padding: 20px !important;

      &__title {
        max-width: none !important;
      }
    }

    &__info {
      &__content {
        padding: 30px !important;
      }
    }

    &__clients {
      padding: 30px !important;

      &__items {
        padding: 30px !important;
      }
    }
  }
}
