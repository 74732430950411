.textarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;

  &:focus {
    // border: 1px solid $color-brand-purple;
    outline: none;
  }

  &__group {
    padding: 10px;
  }

  &__prepend {
    padding: 0 12px;
    border-right: 1px solid #d2e5d680;
    cursor: pointer;
  }

  &__append {
    padding: 0 12px;
    border-left: 1px solid #d2e5d680;
    cursor: pointer;
  }

  &__item {
    outline: none;
    display: inline-block;
    width: 100%;
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 5px 40px #00000008;
    border-radius: 5px;
    height: 130px;
    padding: 10px 16.6666666667px;
    font-size: 1rem;
    background-color: transparent;

    &::placeholder {
      color: #a7bda9cf;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__icon {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    width: 90%;
  }

  &__message {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #345948;
    margin-top: 10px;
    display: block;

    &--success {
      color: $color-success;
    }

    &--error {
      color: $color-danger;
    }
  }

  &--invalid {
    border: 1px solid $color-danger !important;

    &:focus {
      border: 1px solid $color-danger !important;
    }
  }

  &__theme {
    &--default {
      & label {
        color: $brand-color-darker !important;
      }

      & .textarea {
        border: 1px solid transparent;

        &--focus {
          border: 1px solid $brand-color-dark;
        }

        &__prepend {
          border-right: 1px solid $brand-color;
        }

        &__append {
          border-left: 1px solid $brand-color;
        }

        &__item {
          box-shadow: 0px 5px 40px #00000008;
          background-color: $brand-color-darker;
          border-radius: 10px !important;
          color: #fff;

          &:disabled {
            background-color: $brand-input-disabled;
          }

          &::placeholder {
            color: #cdcdcd;
          }
        }
      }
    }

    &--normal {
      & label {
        color: $brand-text-color !important;
      }

      & .textarea {
        border: 1px solid $brand-input-border;

        &--focus {
          border: 1px solid $brand-color-dark;
        }

        &__prepend {
          border-right: 1px solid $brand-input-border;
        }

        &__append {
          border-left: 1px solid $brand-input-border;
        }

        &__item {
          box-shadow: 0px 5px 40px #00000008;
          background-color: transparent;
          color: $brand-color-darker;

          &:disabled {
            background-color: $brand-input-disabled;
          }

          &::placeholder {
            color: $brand-input-placeholder;
          }
        }
      }
    }

    &--white {
      & label {
        color: #fff !important;
      }

      & .textarea {
        border: 1px solid #fff;

        &--focus {
          background-color: #fff;
        }

        &__prepend {
          border-right: 1px solid #fff;
        }

        &__append {
          border-left: 1px solid #fff;
        }

        &__item {
          box-shadow: 0px 5px 40px #00000008;
          background-color: transparent;
          color: #000;

          &:disabled {
            background-color: $brand-input-disabled;
          }

          &::placeholder {
            color: #d4d4d4;
          }
        }
      }
    }
  }
}
