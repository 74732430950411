.Footer {
  &--blue {
    min-height: 500px;
    background-image: url(../../assets/images/shared/footer-bg.png);
    background-size: cover;

    & .Footer {
      &__inner {
        color: #fff;

        &__links {
          & h4 {
            color: #fff;
          }
        }

        &__link {
          color: #fff !important;
        }

        &__contact {
          & a {
            color: #fff;
          }
        }

        &__subscribe {
          & h4 {
            color: #fff;
          }
        }
      }
    }
  }

  &--white {
    min-height: 500px;
    background-image: url(../../assets/images/shared/circle-dots-blue.png);
    background-repeat: no-repeat;
    background-position: 1300px 300px;

    & .Footer {
      &__inner {
        &__links {
          & h4 {
            color: $brand-color-darker;
          }
        }

        &__link {
          color: $brand-color-darker !important;
        }

        &__contact {
          & a {
            color: $brand-color-darker;
          }
        }

        &__subscribe {
          & h4 {
            color: $brand-color-darker;
          }
        }
      }
    }
  }

  &__inner {
    padding: 30px;
    margin: auto;
    max-width: 1437px;

    &__content {
      text-align: left;
      padding: 50px;

      &--greetings {
        margin-top: 100px;
      }

      & img {
        max-width: 127px;
        margin-bottom: 50px;
        margin-left: -10px;
      }

      & p {
        font-size: 200;
        line-height: 2.6rem;
      }

      &__socials {
        margin-top: 30px;
        margin-left: 10px;

        & a {
          margin-right: 25px;

          & > img {
            max-width: 20px;
          }
        }

        &--dark {
          background-color: $brand-color-dark;
          border-radius: 10px;
          padding: 30px;
          max-height: 85px;
          margin-left: 0;
          align-items: flex-start;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &__links {
      padding: 60px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      text-align: left;

      & h4 {
        font-weight: bold;
        margin-bottom: 50px;
      }
    }

    &__link {
      margin-bottom: 35px;
      text-decoration: none !important;
    }

    &__contact {
      padding: 60px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      text-align: left;

      & h4 {
        font-weight: bolder;
      }

      & span {
        display: block;
        margin-bottom: 20px;
      }
    }

    &__subscribe {
      padding: 60px;
      margin-top: 10px;

      & h4 {
        font-weight: bold;
        margin-bottom: 50px;
      }

      &--button {
        text-align: right;
        margin-top: -25px;
      }

      & input {
        outline: none;
        border: none;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-lg) {
  & .Footer {
    &__inner {
      margin: auto 0 !important;
      padding: 0px !important;
    }
  }
}

@media (max-width: $breakpoint-bs-lg) {
  & .Footer {
    &__inner {
      &__content {
        &--greetings {
          display: none;
        }
      }
    }
  }
}
