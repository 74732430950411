.Projects {
  &__header {
    width: 100%;
    padding-left: 50px;
  }

  &__title {
    background-image: url(../../assets/images/home/text-dot-circles.png);
    background-repeat: no-repeat;
    background-position: left;
    padding: 50px;
    margin-left: 100px;
    margin-top: 100px;
    max-width: 700px;
    font-size: 30px;
    font-weight: 600;
  }

  &__group {
    background-image: url(../../assets/images/services/dotted-circles.png);
    background-repeat: no-repeat;
    background-position: top right;
    margin: auto;
    max-width: 1500px;
    margin-top: 30px;
    padding: 100px;
  }

  &__item {
    margin-bottom: 30px;

    & a {
      position: relative;
      display: block;
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 20px;

      &:hover {
        & > img {
          transform: scale(1.2);
        }
      }

      // &::before {
      //   background-image: linear-gradient(
      //     358.95deg,
      //     #000 4.38%,
      //     transparent 39.37%
      //   );
      //   z-index: 2;
      //   border-radius: 8px;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      // }
    }

    & img {
      position: relative;
      height: 370px;
      transition: transform 8s ease;
      border-radius: 20px;
      width: 100%;
      object-fit: cover;
      max-width: 100%;
      z-index: -1;
    }

    &--hover {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2rem;
      z-index: 3;
      border-radius: 8px;
      background-color: #00000080;
      height: 100%;
      position: absolute;
      width: 100%;
      border-radius: 20px;
      transition: all 0.8s ease-in-out;
      color: #fff;

      &:hover {
        background: linear-gradient(
          0deg,
          rgba(1, 5, 16, 0.792) 6.02%,
          rgba(37, 39, 45, 0.52) 26.2%,
          rgba(196, 196, 196, 0) 45.78%
        ) !important;
      }

      &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 30px;

        & small {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Projects {
    &__title {
      padding: 20px !important;
      margin-left: 20px !important;
    }

    &__group {
      padding: 20px !important;
    }
  }
}
