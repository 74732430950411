.Home {
  &__header {
    min-height: 700px;
    background-size: cover;
    background-repeat: no-repeat;

    &__content {
      padding: 50px;
      padding-top: 230px;
      padding-left: 150px;
      color: #fff;

      & h2 {
        max-width: 800px;
        font-weight: 700;
        line-height: 4.9rem;
        font-size: 4rem;
      }

      & p {
        max-width: 700px;
        line-height: 2.3rem;
        font-size: 1.2rem;
      }
    }

    &__next {
      margin-top: -450px;
      margin-bottom: 450px;
      margin-right: 50px;
      text-align: right;
      cursor: pointer;
    }

    &__logos {
      background-color: #fff;
      border-radius: 10px;
      padding: 5px 30px;
      margin: 0 auto;
      text-align: center;
      max-width: fit-content;
      margin-top: -70px;
      box-shadow: 0px 15px 50px #264d604d;

      & img {
        max-width: 119px;
        margin: 30px 0;
        margin-right: 35px;

        &:first-of-type {
          margin-top: 50px;
        }
      }
    }
  }

  &__services {
    min-height: 400px;
    max-width: 1800px;
    margin: auto;
    background-image: url(../../assets/images/home/dotted-circle.png);
    background-repeat: no-repeat;
    background-position: right -190px;
    padding: 100px;
    color: #264d60;

    &__sub {
      // & .col-md-6 {
      //   margin-bottom: 30px;
      // }

      &--down {
        margin-top: 70px;

        &:last-of-type {
          margin-top: 30px;
        }
      }

      &--up {
        margin-top: -120px;
      }
    }

    &__card {
      box-shadow: 0px 15px 50px #264d6033;
      border-radius: 20px;
      padding: 50px;
      transition: all 0.3s ease-in-out;
      background-color: #fff;
      // min-height: 450px;

      &:hover {
        background-image: url(../../assets/images/shared/card-hover.png),
          linear-gradient(
            100.15deg,
            #558CFF 14.69%,
            #6D55FF 50.01%,
            #833DCB 77.68%
          );
        box-shadow: 0px 35px 50px #3399ff59;
        background-repeat: no-repeat;
        background-position: 108% -6%;
        color: #fff;

        & .Home__services__card--more a {
          color: #fff;
        }
      }

      & h5 {
        font-weight: 600;
      }

      & p {
        font-weight: 200;
        line-height: 2.3rem;
        margin-top: 20px;
      }

      &--more {
        & a {
          color: #264d60;
          text-decoration: none !important;
        }
      }
    }

    &__content {
      padding: 60px;
      margin: 150px auto;
      color: $brand-cool-blue;
      line-height: 2.3rem;
    }
  }

  &__divider {
    background: linear-gradient(
      90deg,
      #ffffff -23.2%,
      rgba(183, 183, 183, 0.359013) 54.09%,
      rgba(255, 255, 255, 0) 135.05%
    );
    width: 150px;
    min-height: 3px;
    margin: 30px 0;
  }

  &__impact {
    background-color: #c8c8c83b;
    min-height: 400px;
    padding-left: 100px;
    padding-top: 100px;
    background-image: url(../../assets/images/home/text-dot-circles.png);
    background-repeat: no-repeat;
    background-position: 77px 84px;
    color: #264d60;

    & small {
      font-size: 1.2rem;
    }

    & button {
      min-width: 20px !important;
    }

    & h3 {
      max-width: 700px;
    }

    &__inner {
      max-width: 1400px;
      margin: auto;
      padding-left: 30px;
    }
  }

  &__info {
    background-image: url(../../assets/images/home/info-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;

    &__inner {
      min-height: 600px;
      color: #fff;
      padding: 40px;
      padding-top: 120px;
      padding-left: 130px;

      & h3 {
        max-width: 600px;
        margin-bottom: 30px;
        line-height: 2.8rem;
      }

      & p {
        max-width: 600px;
        line-height: 3rem;
      }
    }
  }

  &__clients {
    max-width: 1800px;
    margin: auto;
    min-height: 400px;
    padding: 100px;
    background-image: url(../../assets/images/home/text-dot-circles.png);
    background-repeat: no-repeat;
    background-position: 100px 59px;

    & h3 {
      color: $brand-color-darker;
      margin-left: 45px;
    }

    &__items {
      padding: 100px;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      padding: 50px 25px;
      text-align: center;
      border-right: 0.5px solid #eeeeee;
      border-bottom: 0.5px solid #eeeeee;

      &:last-of-type {
        border-right: none;
      }

      & img {
        max-width: 220px;
        filter: grayscale(100%);
        transition: filter 0.8s ease-in-out;

        &:hover {
          filter: grayscale(0%) !important;
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .Home {
    &__header {
      &__content {
        padding-left: 250px !important;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Home {
    &__header {
      &__content {
  
        & h2 {
          max-width: 400px;
          line-height: 2.9rem;
          font-size: 2rem;
        }
  
        & p {
          max-width: 400px;
          line-height: 2.3rem;
          font-size: 1rem;
        }
      }

      &__logos {
        margin: 0 20px !important;
        margin-top: -120px !important;
      }
    }

    &__services {
      &__sub {
        &--down {
          margin-top: 20px !important;
        }

        &--up {
          margin-top: 20px !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Home {
    &__header {
      &__content {
        padding-left: 30px !important;
      }

      &__next {
        margin-top: -850px !important;
        margin-bottom: 650px !important;
        margin-right: 30px !important;
        margin-left: 0px;

        & svg {
          max-width: 80px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Home {
    &__services {
      padding: 20px !important;
    }

    &__impact {
      padding-left: 20px !important;

      & .Projects__group {
        padding-left: 0px !important;
      }
    }

    &__info {
      background-size: cover !important;

      &__inner {
        padding-left: 40px !important;
      }
    }

    &__clients {
      padding: 30px !important;

      & h3 {
        margin-left: 20px !important;
      }

      &__items {
        padding: 20px !important;
      }
    }
  }
}
