.Header {
  background-color: transparent;

  &--hasOverlay {
    position: absolute;
    width: 100%;

    & .Header__links {
      &--small {
        & i {
          color: #fff !important;
        }
      }
    }

    & .Header__link {
      color: #fff !important;

      &:hover {
        color: #cdcdcd !important;
      }

      &--active {
        padding-bottom: 10px;
        border-bottom: 2px solid $brand-color !important;
      }

      &__btn {
        color: #fff !important;
        background-color: $brand-color !important;
        border: none;
      }
    }
  }

  &--overlayBlue {
    position: absolute;
    width: 100%;

    & .Header__links {
      &--small {
        & i {
          color: #fff !important;
        }
      }
    }

    & .Header__link {
      color: #fff !important;

      &:hover {
        color: #dfdfdf !important;
      }

      &--active {
        padding-bottom: 10px;
        font-weight: 800;
      }

      &__btn {
        color: #fff !important;
        background-color: transparent !important;
        border: 2px solid #fff;
      }
    }
  }

  &__inner {
    padding: 40px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: -25px 0;
  }

  &__logo {
    max-width: 126px;

    & img {
      max-width: 100%;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-top: 20px;
    max-height: 200px;
    max-width: 800px;

    &--small {
      display: none;

      & i {
        color: $brand-header-color;
        font-size: 25px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }

  &__link {
    color: $brand-header-color;
    text-decoration: none !important;
    cursor: pointer;

    &:hover {
      color: #8d8d8d !important;
    }

    & i {
      margin-left: 8px;
      font-size: 18px;
    }

    &--active {
      color: $brand-color-dark;
    }

    &__services {
      &:hover {
        & .Header__link__subMenu {
          display: block !important;
        }
      }

      & i {
        font-size: 13px;
      }
    }

    &__btn {
      color: $brand-color-dark;
      text-decoration: none !important;
      background-color: transparent;
      border-radius: 8px;
      border: 2px solid $brand-color-dark;
      text-align: center;
      padding: 13px;
      margin-top: -15px;

      &:hover {
        color: $brand-color-dark;
      };
    }

    &__subMenu {
      max-width: 300px;
      background-color: #fff;
      position: absolute;
      z-index: 200;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      margin-top: 15px;
      margin-left: -100px;
      box-shadow: 0px 0px 2px 1px #264d6033;
      transition: all 0.3s ease-in-out;
      display: none;

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 25px;
        color: $brand-header-color !important;
        text-decoration: none !important;

        &:hover {
          background-color: #83bdf428;
        }

        &--active {
          background-color: #83bdf423;
        }
      }
    }
  }

  &__small {
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-color: #fff;

    &__heading {
      display: flex;
      justify-content: space-between;
      padding: 30px;

      & i {
        color: #cf2440cb;
        font-size: 25px;
        margin-top: 17px;
      }

      & img {
        width: 120px;
      }
    }

    &:hover {
      color: #8d8d8d !important;
    }

    &__link {
      padding: 30px;
      display: block;
      text-align: left;
      color: $brand-header-color !important;
      text-decoration: none !important;
      margin-bottom: 5px;

      &:hover {
        background-color: #83bdf428;
      }

      &--active {
        background-color: #83bdf423;
      }

      &__btn {
        color: #fff !important;
        text-decoration: none !important;
        background-color: $brand-color;
        text-align: center;
        padding: 20px;
        display: block;
        margin-top: 10px;

        &:hover {
          color: $brand-color-dark;
        };
      }
    }

    &__sub {
      & a {
        padding-left: 50px;
      }

      &--heading {
        text-align: left;
        padding: 10px;
        padding-left: 30px;
        color: #b3b3b3;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Header {
    &__links {
      display: none !important;
      &--small {
        display: block !important;
      }
    }
  }
}
