.ProjectLayout {
  &__header {
    padding: 30px;
    padding-top: 0px;
    position: relative;

    & img {
      height: 650px;
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
    }

    &__overlay {
      position: absolute;
      left: 30px;
      top: 0;
      right: 30px;
      bottom: 30px;
      border-radius: 20px;
      background: linear-gradient(
        243.69deg,
        rgba(38, 77, 96, 0.12) 12.41%,
        #0b427b 104.12%
      );

      &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 30px;
        color: #fff;
      }
    }
  }

  &__content {
    padding: 70px;
    background-image: url(../../assets/images/projectLayout/text-dotted-circles.png);
    background-repeat: no-repeat;
    background-position: 2% 0%;
    margin: auto;
    max-width: 1500px;

    & > h4 {
      color: #4f4f4fb2;
    }

    &__inner {
      & h2 {
        color: #063768;
        font-weight: bolder;
        margin-bottom: 30px;
      }

      & span {
        color: #063768;
        font-weight: 200;
        font-size: 15px;
        line-height: 2.5rem;
      }

      & .col-md-6 {
        margin-bottom: 30px;
      }
    }
  }

  &__gallery {
    padding: 30px;

    &__inner {
      margin: auto;
      max-width: 1500px;
    }

    &__item {
      margin-bottom: 30px;

      & img {
        width: 100%;
        height: 430px;
        border-radius: 20px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
