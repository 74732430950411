.Contact {
  &__header {
    min-height: 700px;
    background: linear-gradient(
      102.31deg,
      #1978db 0%,
      #6D55FF 63.9%,
      #833DCB 95.78%
    );

    &__inner {
      background-image: url(../../assets/images/contact/dotted-circles.png);
      background-repeat: no-repeat;
      background-position: right;
      min-height: 700px;
    }

    &__content {
      padding: 100px;
      padding-top: 200px;
      color: #fff;
      line-height: 2.3rem;
      text-align: center;
      margin: auto;
      margin-bottom: 20px;

      & h1 {
        margin-bottom: 20px;
        font-weight: 700;
      }
    }
  }

  &__form {
    padding: 30px;
    background-image: url(../../assets/images/home/text-dot-circles.png);
    background-repeat: no-repeat;
    background-position: left;
    margin: auto;

    &__inner {
      background-color: #fff;
      border-radius: 10px;
      max-width: 1100px;
      margin: auto;
      margin-top: -350px;
      color: #979ea2;
      padding: 20px 50px;
      padding-top: 80px;
      min-height: 600px;
      box-shadow: 0px 20px 30px #5f6b7142;
    }

    &__info {
      padding: 25px 10px;
      font-size: 14px;
      font-weight: 200;
    }

    &__btn {
      padding: 25px 10px;

      & button {
        min-width: 20px !important;
      }
    }
  }

  &__items {
    padding: 30px;

    &__inner {
      max-width: 1100px;
      margin: auto;
      padding-top: 50px;
    }
  }

  &__item {
    &__icon {
      border-radius: 15px;
      background-color: transparent;
      padding: 30px;
      padding-bottom: 20px;
      min-height: 56px;
      margin: 0px auto;
      max-width: 135px;

      & svg {
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      text-align: center;

      & h4 {
        font-weight: 700;
        color: #264d60;
      }

      & p {
        font-size: 15px;
        color: #7b8898;
        max-width: 280px;
        margin: 15px auto;
      }

      & a {
        font-size: 15px;
        color: $brand-color-dark;
        font-weight: 700;
      }
    }
  }

  &__map {
    padding: 30px;

    &__inner {
      max-width: 1100px;
      margin: 30px auto;

      & iframe {
        border: 0px;
        width: 100%;
        border-radius: 15px;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Contact {
    &__header {
      &__content {
        padding: 30px !important;
        padding-top: 150px !important;
      }
    }
  }
}
